import { environment } from "../environments/environment";

export const dashboardVersion =
  "v2.2 " + (environment.production ? "prod" : "staging");

export const getTcUrl = (
  options: { ws?: boolean; region?: string; env?: string } = {
    ws: false,
    region: environment.DEFAULT_REGION,
    env: environment.TC_ENV,
  }
): string => {
  options.ws = options.ws || false;
  options.region = options.region || environment.DEFAULT_REGION;
  options.env = options.env || environment.TC_ENV;
  let tcUrl = options.ws ? environment.WS_URL : environment.API_URL;
  if (environment.MANAGE_REGION === "true") {
    tcUrl = `${environment.TC_SVR_PROTOCOL}://api-${options.region}-${
      options.env
    }.${environment.TC_SVR_DOMAIN}${
      environment.TC_SVR_PORT ? `:${environment.TC_SVR_PORT}` : ""
    }/${options.ws ? "" : "api/admin"}`;
  }
  return tcUrl;
};

export const getAllTcUrls = (
  options: { ws?: boolean; region?: string; env?: string } = {
    ws: false,
    region: environment.DEFAULT_REGION,
    env: environment.TC_ENV,
  }
): string[] => {
  if (environment.MANAGE_REGION !== "true") {
    return [getTcUrl(options)];
  }
  const apiUrls = [];
  const allRegionsInUse = environment.ALL_REGIONS_IN_USE.split(",");
  for (let i = 0; i < allRegionsInUse.length; i++) {
    const regionInUse = allRegionsInUse[i];
    apiUrls.push(
      getTcUrl({ env: options.env, ws: options.ws, region: regionInUse })
    );
  }
  return apiUrls;
};
