import { Component, Input } from "@angular/core";
import { navItems } from "./../../_nav";
import { JwtHelper } from "angular2-jwt";
import { dashboardVersion } from "../../../helpers/utils";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent {
  public navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public currentYear: number;
  public currentUser: any = {};
  public dashboardVersion = dashboardVersion;
  constructor() {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized =
        document.body.classList.contains("sidebar-minimized");
    });

    this.changes.observe(<Element>this.element, {
      attributes: true,
    });

    this.currentYear = new Date().getFullYear();

    try {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (error) {}
    const isAdmin = this.currentUser && this.currentUser.id == 1;
    this.navItems = navItems.filter((value) => {
      return isAdmin || !value.onlyAdmin;
    });

    // force a signOut when jwt token expire
    try {
      const jwtHelper = new JwtHelper();
      const token = localStorage.getItem("token");
      const jwtExpDate = jwtHelper.getTokenExpirationDate(token);
      console.log("jwt expiration date:", jwtExpDate.toISOString());
      const timeoutMs = jwtExpDate.getTime() - Date.now();
      if (timeoutMs > 0) {
        setTimeout(() => {
          console.log("jwt token expired, signing out...");
          this.signOut();
        }, timeoutMs);
      } else {
        this.signOut();
      }
    } catch (error) {
      console.error(error);
      this.signOut();
    }
  }

  signOut() {
    try {
      localStorage.clear();
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    } catch (error) {
      console.error(error);
    } finally {
      window.location.replace("/");
    }
  }
}
